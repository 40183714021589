import React from 'react';
import { Provider } from 'react-redux';
import store from './store';

function provideStoreToComponent<P extends object>(
  Component: React.ComponentType<P>
) {
  class WrappedComponent extends React.Component<P> {
    static displayName = `Wrapped(${getDisplayName(Component)})`;

    render() {
      return (
        <Provider store={store}>
          <Component {...this.props} />
        </Provider>
      );
    }
  }

  return WrappedComponent;
}

function getDisplayName(WrappedComponent: React.ComponentType) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}

export default provideStoreToComponent;
