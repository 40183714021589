import axios from 'axios';
import $ from 'jquery';

import { take, get } from 'lodash';
import Fuse from 'fuse.js';

const token = $('meta[name="csrf-token"]').attr('content');

const spreeAxios = axios.create({
  headers: {
    'X-Spree-Order-Token': get(window, 'ENV.orderToken'),
    'Content-Type': 'application/json',
    Accept: 'application/json',
    'X-CSRF-Token': token
  }
});

interface SearchResult {
  id: number;
  name: string;
  path: string;
  taxons: string[];
}

let searchAutocomplete: Fuse<SearchResult>;
let searchAutocompletePromise: Promise<Fuse<SearchResult>>;

function loadSearchAutocomplete() {
  if (searchAutocomplete != null) {
    return Promise.resolve(searchAutocomplete);
  }

  if (searchAutocompletePromise == null) {
    searchAutocompletePromise = new Promise((resolve, reject) => {
      spreeAxios.get('/api/search_autocomplete.json').then((response) => {
        searchAutocomplete = new Fuse(response.data, {
          keys: ['name', 'taxons']
        });

        resolve(searchAutocomplete);
      }, reject);
    });
  }

  return searchAutocompletePromise;
}

export function performSearch(searchTerm: string): Promise<SearchResult[]> {
  if (typeof searchTerm !== 'string' || searchTerm.length < 3) {
    return Promise.resolve([]);
  }

  return new Promise((resolve, reject) => {
    loadSearchAutocomplete()
      .then((autocomplete) => {
        resolve(
          take(autocomplete.search(searchTerm), 10).map(({ item }) => item)
        );
      })
      .catch(reject);
  });
}
