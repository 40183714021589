import { call, put, takeEvery, select } from 'redux-saga/effects';
import * as actionCreators from './action-creators';
import * as requests from './requests';
import globalData from 'utils/global-data';
import { licensesWithState } from 'modules/font-licensing/getters';
import GlobalState from 'GlobalState';
import trackAddToCart from 'utils/trackAddToCart';

function* fontLicensingSaga() {
  yield takeEvery('CALCULATE_LICENSE_TOTALS_START', calculateLicenseTotalsSaga);
  yield takeEvery('ADD_LICENSE_STYLES', calculateLicenseTotalsSaga);
  yield takeEvery('REMOVE_LICENSE_STYLES', calculateLicenseTotalsSaga);
  yield takeEvery('UPDATE_LICENSE_OPTION', calculateLicenseTotalsSaga);
  yield takeEvery('ADD_LICENSE_TO_ORDER_START', addLicenseToOrderSaga);
}

export function* calculateLicenseTotalsSaga() {
  const state: GlobalState = yield select();
  const licenses = licensesWithState(state);
  const { fontFamily } = globalData();

  if (fontFamily == null) {
    throw new Error('Missing font family.');
  }

  try {
    // @ts-ignore
    const response = yield call(
      // @ts-ignore
      requests.calculateTotal,
      licenses,
      fontFamily.id
    );

    yield put(actionCreators.calculateLicenseTotalsSuccess(response.data));
  } catch (error) {
    yield put(actionCreators.calculateLicenseTotalsFailed());
  }
}

export function* addLicenseToOrderSaga() {
  const { fontFamily } = globalData();

  if (fontFamily == null) {
    throw new Error('Missing font family.');
  }

  const state: GlobalState = yield select();
  const licenses = licensesWithState(state);

  for (let license of licenses) {
    if (license.type === 'web') {
      if (
        license.options.domain_names === undefined ||
        license.options.domain_names === ''
      ) {
        yield put(actionCreators.missingDomainName());
        return;
      }
    }
  }

  try {
    // @ts-ignore
    const response = yield call(requests.addToOrder, licenses, fontFamily.id);

    try {
      trackAddToCart({
        fontFamilyId: fontFamily.id,
        order: response.data.order
      });
    } catch (error) {
      if (window.bugsnagClient != null) {
        window.bugsnagClient.notify(error);
      }
    }

    yield put(actionCreators.addLicenseToOrderSuccess(response.data));

    window.location.href = '/cart';
  } catch (error) {
    yield put(actionCreators.addLicenseToOrderFailed());
  }
}

export default fontLicensingSaga;
