import { call, put, takeEvery } from 'redux-saga/effects';
import * as actionCreators from './action-creators';
import { showFlashMessage } from 'modules/flash-messages/action-creators';
import * as requests from './requests';
import trackAddToCart from 'utils/trackAddToCart';

function* currentOrderSaga() {
  yield takeEvery('ADD_LINE_ITEM_START', addLineItemSaga);
  yield takeEvery('REMOVE_LINE_ITEM_START', removeLineItemSaga);
  yield takeEvery('UPDATE_LINE_ITEM_START', updateLineItemSaga);
}

export function* addLineItemSaga(
  action: actionCreators.AddLineItemStartAction
) {
  const { variantId, quantity } = action;

  try {
    // @ts-ignore;
    const response = yield call(requests.addLineItem, variantId, quantity);

    yield put(actionCreators.addLineItemSuccess(response.data.order));

    try {
      trackAddToCart({
        variantId,
        quantity,
        order: response.data.order
      });
    } catch (error) {
      if (window.bugsnagClient != null) {
        window.bugsnagClient.notify(error);
      }
    }
  } catch (error) {
    yield put(actionCreators.addLineItemFailure());
    yield put(showFlashMessage('Failed to add item.'));
  }
}

export function* removeLineItemSaga(
  action: actionCreators.RemoveLineItemStartAction
) {
  const { lineItemId, orderNumber } = action;

  try {
    yield call(requests.removeLineItem, lineItemId, orderNumber);
    yield put(actionCreators.removeLineItemSuccess(lineItemId));
  } catch (error) {
    yield put(actionCreators.removeLineItemFailure(lineItemId));
    yield put(showFlashMessage('Failed to remove item.'));
  }
}

export function* updateLineItemSaga(
  action: actionCreators.UpdateLineItemStartAction
) {
  const { lineItemId, quantity, orderNumber } = action;

  try {
    const { data: responseData } = yield call(
      requests.updateLineItem,
      lineItemId,
      quantity,
      orderNumber
    );

    const total = parseFloat(responseData.total);

    if (quantity > responseData.quantity) {
      yield put(
        showFlashMessage(
          `You can't add more than ${responseData.quantity} of that item to your order!`
        )
      );
    }

    yield put(
      actionCreators.updateLineItemSuccess(
        lineItemId,
        total,
        responseData.quantity
      )
    );
  } catch (error) {
    yield put(actionCreators.updateLineItemFailure(lineItemId));
    yield put(showFlashMessage('Failed to update item!'));
  }
}

export default currentOrderSaga;
