// https://github.com/filamentgroup/woff2-feature-test

let result: boolean;

const supportsWOFF2 = (): boolean => {
  if (result != null) {
    return result;
  }

  if (!('FontFace' in window)) {
    result = false;

    return result;
  }

  // @ts-ignore
  var fontFace = new FontFace(
    't',
    'url( "data:application/font-woff2;base64,d09GMgABAAAAAADwAAoAAAAAAiQAAACoAAEAAAAAAAAAAAAAAAAAAAAAAAAAAAAABmAALAogOAE2AiQDBgsGAAQgBSAHIBuDAciO1EZ3I/mL5/+5/rfPnTt9/9Qa8H4cUUZxaRbh36LiKJoVh61XGzw6ufkpoeZBW4KphwFYIJGHB4LAY4hby++gW+6N1EN94I49v86yCpUdYgqeZrOWN34CMQg2tAmthdli0eePIwAKNIIRS4AGZFzdX9lbBUAQlm//f262/61o8PlYO/D1/X4FrWFFgdCQD9DpGJSxmFyjOAGUU4P0qigcNb82GAAA" ) format( "woff2" )',
    {}
  );

  fontFace.load()['catch'](() => {});

  result = fontFace.status == 'loading' || fontFace.status == 'loaded';

  return result;
};

export default supportsWOFF2;
