import { set, setIn, update, updateIn } from 'immutable';
import { setUnion, setSubtract } from 'utils/sets';
import globalData from 'utils/global-data';
import GlobalState from 'GlobalState';
import { Action } from './action-creators';

export const constructInitialState = (): GlobalState['fontLicensing'] => {
  const { licenseOptions, fontFamily } = globalData();

  let selectedStyles;

  // If we start with the licensing modal visible, then no styles should be selected initially.
  if (globalData().showLicensing || fontFamily == null) {
    selectedStyles = {
      desktop: new Set<number>([]),
      web: new Set<number>([]),
      app: new Set<number>([])
    };
  } else {
    selectedStyles = {
      desktop: new Set<number>(fontFamily.styles.map((style) => style.id)),
      web: new Set<number>([]),
      app: new Set<number>([])
    };
  }

  let selectedOption;

  if (licenseOptions == null) {
    selectedOption = {
      desktop: null,
      web: null,
      app: null
    };
  } else {
    selectedOption = {
      desktop: licenseOptions.desktop[0].id,
      web: licenseOptions.web[0].id,
      app: licenseOptions.app[0].id
    };
  }

  return {
    isVisible: false,
    isActing: false,
    selectedOption,
    selectedStyles,
    totals: {
      desktop: 0,
      web: 0,
      app: 0
    },
    modalOptions: {
      heading: null,
      content: null,
      size: null
    },
    domainNames: '',
    missingDomainNames: false
  };
};

function fontLicensingReducer(
  state = constructInitialState(),
  action: Action
): GlobalState['fontLicensing'] {
  if (state == null) {
    return state;
  }

  switch (action.type) {
    case 'TOGGLE_LICENSE_SECTION':
      return { ...state, isVisible: action.isVisible };

    case 'UPDATE_LICENSE_OPTION':
      return setIn(state, ['selectedOption', action.licenseType], action.id);

    case 'ADD_LICENSE_STYLES':
      return updateIn(state, ['selectedStyles', action.licenseType], (styles) =>
        // @ts-ignore;
        setUnion(styles, new Set(action.ids))
      );

    case 'ADD_DOMAIN_NAMES':
      if (
        state.missingDomainNames &&
        action.domainNames !== undefined &&
        action.domainNames.length > 3
      ) {
        state = set(state, 'missingDomainNames', false);
      }

      return set(state, 'domainNames', action.domainNames);

    case 'REMOVE_LICENSE_STYLES':
      return updateIn(state, ['selectedStyles', action.licenseType], (styles) =>
        // @ts-ignore;
        setSubtract(styles, new Set(action.ids))
      );

    case 'SHOW_LICENSING_MODAL':
      return update(state, 'modalOptions', (options) => ({
        ...options,
        ...action.modalOptions
      }));

    case 'HIDE_LICENSING_MODAL':
      return update(state, 'modalOptions', (options) => ({
        ...options,
        heading: null,
        content: null,
        size: null
      }));

    case 'CALCULATE_LICENSE_TOTALS_SUCCESS':
      return update(state, 'totals', (totals) => ({
        ...totals,
        ...action.totals
      }));

    case 'ADD_LICENSE_TO_ORDER_START':
      return { ...state, isActing: true };

    case 'ADD_LICENSE_TO_ORDER_SUCCESS':
      return { ...state, isActing: false };

    case 'ADD_LICENSE_TO_ORDER_FAILED':
      return { ...state, isActing: false };

    case 'MISSING_DOMAIN_NAME':
      state = set(state, 'isActing', false);
      return set(state, 'missingDomainNames', true);

    default:
      return state;
  }
}

export default fontLicensingReducer;
