import { find, get } from 'lodash';
import globalData from 'utils/global-data';

import GlobalState from 'GlobalState';
import { Action } from './action-creators';

export const initialState: GlobalState['products'] = {
  items: globalData().storeItems || [],
  offset: get(globalData(), 'storeItems.length', 0),
  imageSize: defaultImageSize(),
  searchTerm: globalData().searchTerm || null,
  searchResults: [],
  selectedResultId: null,
  selectedTaxonId: globalData().selectedTaxonId || null
};

function productsReducer(state = initialState, action: Action) {
  let selectedResultId = get(state, 'searchResults[0].id');

  switch (action.type) {
    case 'RESET_OFFSET':
      return {
        ...state,
        items: globalData().storeItems,
        offset: get(globalData(), 'storeItems.length', 0)
      };

    case 'PUSH_ITEMS':
      return {
        ...state,
        items: state.items.concat(action.items),
        offset: state.offset + action.items.length
      };

    case 'UPDATE_IMAGE_SIZE':
      return { ...state, imageSize: action.imageSize };

    case 'UPDATE_SEARCH_TERM':
      return { ...state, searchTerm: action.searchTerm };

    case 'UPDATE_SEARCH_RESULTS':
      return { ...state, searchResults: action.searchResults };

    case 'SELECT_NEXT_RESULT':
      if (state.selectedResultId != null) {
        const selectedResult = find(state.searchResults, (result) => {
          return result.id === state.selectedResultId;
        });

        const selectedResultIndex =
          selectedResult != null
            ? state.searchResults.indexOf(selectedResult)
            : -1;

        selectedResultId =
          get(state, `searchResults[${selectedResultIndex + 1}].id`) ||
          state.searchResults[0].id;
      }

      return { ...state, selectedResultId };

    case 'SELECT_PREV_RESULT':
      if (state.selectedResultId != null) {
        const selectedResult = find(state.searchResults, (result) => {
          return result.id === state.selectedResultId;
        });

        const selectedResultIndex =
          selectedResult != null
            ? state.searchResults.indexOf(selectedResult)
            : -1;

        selectedResultId =
          get(state, `searchResults[${selectedResultIndex - 1}].id`) ||
          state.searchResults[state.searchResults.length - 1].id;
      }

      return { ...state, selectedResultId };

    case 'UPDATE_SELECTED_TAXON':
      return { ...state, selectedTaxonId: action.selectedTaxonId };

    default:
      return state;
  }
}

function defaultImageSize(): 'small' | 'medium' | 'large' {
  let storedImageSize;

  try {
    storedImageSize =
      'sessionStorage' in window && sessionStorage.getItem('productsImageSize');
  } catch (error) {
    // Prevent Safari from throwing "SecurityError: DOM Exception 18"
  }

  if (
    storedImageSize === 'small' ||
    storedImageSize === 'medium' ||
    storedImageSize === 'large'
  ) {
    return storedImageSize;
  }

  const { pathname, search } = location;

  if (/^\/fonts/.test(pathname) || /^\?keywords=.+/.test(search)) {
    return 'small';
  }

  if (/^\/work/.test(pathname)) {
    return 'large';
  }

  return 'medium';
}

export default productsReducer;
