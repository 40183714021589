import { Message } from 'types';

type MessageType = 'notice' | 'alert';

interface ShowFlashMessageAction {
  type: 'SHOW_FLASH_MESSAGE';
  content: string;
  messageType: MessageType;
}

interface DismissFlashMessageAction {
  type: 'DISMISS_FLASH_MESSAGE';
  message: Message;
}

export type Action = ShowFlashMessageAction | DismissFlashMessageAction;

export function showFlashMessage(
  content: string,
  messageType: MessageType = 'notice'
): ShowFlashMessageAction {
  return { type: 'SHOW_FLASH_MESSAGE', messageType, content };
}

export function dismissFlashMessage(
  message: Message
): DismissFlashMessageAction {
  return { type: 'DISMISS_FLASH_MESSAGE', message };
}
