declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: Function;
  }
}

import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { all } from 'redux-saga/effects';

import currentOrderSaga from 'modules/current-order/saga';
import fontLicensingSaga from 'modules/font-licensing/saga';
import productsSaga from 'modules/products/saga';
import fontDeclarationsSaga from 'modules/font-declarations/saga';

import optionsReducer from 'modules/options/reducer';
import productImagesReducer from 'modules/product-images/reducer';
import productReducer from 'modules/product/reducer';
import flashMessagesReducer from 'modules/flash-messages/reducer';
import currentOrderReducer from 'modules/current-order/reducer';
import fontLicensingReducer from 'modules/font-licensing/reducer';
import productsReducer from 'modules/products/reducer';
import currentUserReducer from 'modules/current-user/reducer';
import fontDeclarationsReducer from 'modules/font-declarations/reducer';

const sagaMiddleware = createSagaMiddleware();

const reducer = combineReducers({
  options: optionsReducer,
  productImages: productImagesReducer,
  product: productReducer,
  flashMessages: flashMessagesReducer,
  currentOrder: currentOrderReducer,
  fontLicensing: fontLicensingReducer,
  products: productsReducer,
  currentUser: currentUserReducer,
  fontDeclarations: fontDeclarationsReducer,
  homePageForm: (state = { supportsVideo: true }, action) => {
    if ('SUPPORTS_VIDEO' === action.type) {
      // @ts-ignore;
      return { ...state, supportsVideo: action.sectionSupportsVideo };
    }
    return state;
  }
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  reducer,
  {},
  composeEnhancers(applyMiddleware(sagaMiddleware))
);

sagaMiddleware.run(function* () {
  yield all([
    currentOrderSaga(),
    fontLicensingSaga(),
    productsSaga(),
    fontDeclarationsSaga()
  ]);
});

export default store;
