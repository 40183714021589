import GlobalState from 'GlobalState';

export const licensesWithState = (state: GlobalState) => {
  if (state.fontLicensing == null) {
    return [];
  }

  const options = state.fontLicensing.selectedOption;
  const styles = state.fontLicensing.selectedStyles;

  const licenses = [];

  if (styles.desktop.size > 0 && options.desktop !== 'EXTRA') {
    licenses.push({
      type: 'desktop',
      options: {
        workstation_count: options.desktop,
        // @ts-ignore;
        style_ids: [...styles.desktop]
      }
    });
  }

  if (styles.web.size > 0) {
    licenses.push({
      type: 'web',
      options: {
        visitor_count: options.web,
        style_ids: [...styles.web],
        domain_count: 1,
        domain_names: state.fontLicensing.domainNames
      }
    });
  }

  if (styles.app.size > 0) {
    licenses.push({
      type: 'app',
      options: {
        application_count: options.app,
        style_ids: [...styles.app]
      }
    });
  }

  return licenses;
};
