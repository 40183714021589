import axios from 'axios';
import $ from 'jquery';
import { get } from 'lodash';

const token = $('meta[name="csrf-token"]').attr('content');

const spreeAxios = axios.create({
  headers: {
    'X-Spree-Order-Token': get(window, 'ENV.orderToken'),
    'Content-Type': 'application/json',
    Accept: 'application/json',
    'X-CSRF-Token': token
  }
});

export function addLineItem(variantId: number, quantity: number) {
  return spreeAxios.post('/orders/populate', {
    variant_id: variantId,
    quantity
  });
}

export function removeLineItem(lineItemId: number, orderNumber: string) {
  return spreeAxios.delete(
    `/api/orders/${orderNumber}/line_items/${lineItemId}`
  );
}

export function updateLineItem(
  lineItemId: number,
  quantity: number,
  orderNumber: string
) {
  if (lineItemId == null || quantity == null || isNaN(quantity)) {
    return Promise.reject();
  }

  return spreeAxios.put(`/api/orders/${orderNumber}/line_items/${lineItemId}`, {
    quantity
  });
}
