import { remove } from 'immutable';

import GlobalState from 'GlobalState';
import { Action } from './action-creators';

const initialState: GlobalState['flashMessages'] = [];

function flashMessagesReducer(
  state: GlobalState['flashMessages'] = initialState,
  action: Action
) {
  switch (action.type) {
    case 'SHOW_FLASH_MESSAGE': {
      const { content, messageType } = action;

      return [{ content, messageType }, ...state];
    }

    case 'DISMISS_FLASH_MESSAGE': {
      const { message } = action;
      const index = state.indexOf(message);

      return remove(state, index);
    }

    default: {
      return state;
    }
  }
}

export default flashMessagesReducer;
