import { Order } from 'types';

interface AddVariantEvent {
  variantId: number;
  quantity: number;
  order: Order;
}

interface AddFontLicenseEvent {
  fontFamilyId: number;
  order: Order;
}

interface KlaviyoAddedToCartEvent {
  $value: number;
  AddedItemProductName: string;
  AddedItemProductID: string;
  AddedItem_SKU: string | null;
  AddedItem_Categories: string[];
  AddedItem_ImageURL: string;
  AddedItem_URL: string;
  AddedItem_Price: number;
  AddedItem_Quantity: number;
  ItemNames: string[];
  CheckoutURL: string;
  Items: Array<{
    ProductID: string;
    SKU: string | null;
    ProductName: string;
    Quantity: number;
    ItemPrice: number;
    RowTotal: number;
    ProductURL: string;
    ImageURL: string;
    ProductCategories: string[];
  }>;
}

const trackAddToCart = (event: AddVariantEvent | AddFontLicenseEvent) => {
  const { order } = event;

  let quantity: number;

  if ('quantity' in event) {
    quantity = event.quantity;
  } else {
    quantity = 1;
  }

  let lineItem;

  if ('variantId' in event) {
    lineItem = order.line_items.find(
      (lineItem) => lineItem.variant_id === event.variantId
    );
  }

  if ('fontFamilyId' in event) {
    lineItem = order.line_items.find(
      (lineItem) => lineItem.product.font_family_id === event.fontFamilyId
    );
  }

  if (lineItem == null) {
    return;
  }

  const { product } = lineItem;

  window.fbq('track', 'AddToCart', {
    content_type: 'product',
    content_ids: [product.id],
    contents: [
      {
        id: product.id,
        quantity
      }
    ],
    value: lineItem.price * quantity,
    currency: 'USD'
  });

  trackKlaviyoAddedToCart({
    $value: lineItem.price * quantity,
    AddedItemProductName: product.name,
    AddedItemProductID: product.id.toString(),
    AddedItem_SKU: product.sku,
    AddedItem_Categories: product.taxon_names,
    AddedItem_ImageURL: product.image_url,
    AddedItem_URL: product.url,
    AddedItem_Price: lineItem.price,
    AddedItem_Quantity: quantity,
    ItemNames: order.line_items.map((lineItem) => lineItem.product.name),
    CheckoutURL: 'https://houseind.com/checkout',
    Items: order.line_items.map((lineItem) => ({
      ProductID: lineItem.product.id.toString(),
      SKU: lineItem.product.sku,
      ProductName: lineItem.product.name,
      Quantity: lineItem.quantity,
      ItemPrice: lineItem.price,
      RowTotal: lineItem.total,
      ProductURL: lineItem.product.url,
      ImageURL: lineItem.product.image_url,
      ProductCategories: lineItem.product.taxon_names
    }))
  });
};

const trackKlaviyoAddedToCart = (event: KlaviyoAddedToCartEvent) => {
  if (window._learnq == null) {
    window._learnq = [];
  }

  window._learnq.push(['track', 'Added to Cart', event]);
};

export default trackAddToCart;
