import 'url-search-params-polyfill';
import { call, put, takeEvery } from 'redux-saga/effects';
import axios from 'axios';

import supportsWOFF2 from 'utils/supportsWOFF2';

import {
  LoadFontDeclarationAction,
  loadFontDeclarationSuccess
} from './action-creators';

const loadedFontStyleIds = new Set<number>();

function* fontDeclarationsSaga() {
  yield takeEvery('LOAD_FONT_DECLARATION', loadFontDeclaration);
}

function* loadFontDeclaration(action: LoadFontDeclarationAction) {
  const { id } = action.payload;

  if (id == null) {
    return;
  }

  if (!loadedFontStyleIds.has(id)) {
    loadedFontStyleIds.add(id);

    try {
      const params = new URLSearchParams();

      if (supportsWOFF2()) {
        params.set('preferred_format', 'woff2');
      } else {
        params.set('preferred_format', 'woff');
      }

      const data: {
        id: number;
        className: string;
        declaration: string;
      } = yield call(() =>
        axios
          .get(`/api/font-declarations/${id}.json?${params.toString()}`)
          .then((response) => response.data)
      );

      yield put(loadFontDeclarationSuccess(data));
    } catch (error) {
      loadedFontStyleIds.delete(id);

      console.error(error);
    }
  }
}

export default fontDeclarationsSaga;
