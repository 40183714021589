import axios, { CancelTokenSource } from 'axios';
import $ from 'jquery';

import { LicenseType, Order } from 'types';

const token = $('meta[name="csrf-token"]').attr('content');

const spreeAxios = axios.create({
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    'X-CSRF-Token': token
  }
});

interface FontLicense {
  type: LicenseType;
  options: {
    workstation_count: number;
    visitor_count: number;
    application_count: number;
    domain_count: number;
    domain_names: string;
    style_ids: number[];
  };
}

export function addToOrder(
  licenses: FontLicense[],
  familyId: number
): Promise<{ data: Order }> {
  return spreeAxios.post('/orders/populate_font.json', {
    font_family_id: familyId,
    font_licenses: licenses
  });
}

let currentCancelTokenSource: CancelTokenSource | null = null;

export function calculateTotal(licenses: FontLicense[], familyId: number) {
  if (currentCancelTokenSource != null) {
    currentCancelTokenSource.cancel();
  }

  const data = { font_family_id: familyId, font_licenses: licenses };

  currentCancelTokenSource = axios.CancelToken.source();

  return spreeAxios
    .post('/api/font_licenses/calculate.json', data, {
      cancelToken: currentCancelTokenSource.token
    })
    .finally(() => {
      currentCancelTokenSource = null;
    });
}
