import { LoadFontDeclarationSuccessAction } from './action-creators';

export interface State {
  readonly [fontStyleId: number]: {
    readonly className: string;
    readonly declaration: string;
  };
}

function fontDeclarationsReducer(
  state: State = {},
  action: LoadFontDeclarationSuccessAction
): State {
  if (action.type === 'LOAD_FONT_DECLARATION_SUCCESS') {
    return {
      ...state,
      [action.payload.id]: {
        className: action.payload.className,
        declaration: action.payload.declaration
      }
    };
  }

  return state;
}

export default fontDeclarationsReducer;
