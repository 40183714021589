export interface LoadFontDeclarationAction {
  type: 'LOAD_FONT_DECLARATION';
  payload: {
    id: number;
  };
}

export function loadFontDeclaration(
  payload: LoadFontDeclarationAction['payload']
): LoadFontDeclarationAction {
  return { type: 'LOAD_FONT_DECLARATION', payload };
}

export interface LoadFontDeclarationSuccessAction {
  type: 'LOAD_FONT_DECLARATION_SUCCESS';
  payload: {
    id: number;
    className: string;
    declaration: string;
  };
}

export function loadFontDeclarationSuccess(
  payload: LoadFontDeclarationSuccessAction['payload']
): LoadFontDeclarationSuccessAction {
  return { type: 'LOAD_FONT_DECLARATION_SUCCESS', payload };
}
