import globalData from 'utils/global-data';

const constructInitialState = function () {
  return globalData().currentUser || null;
};

function currentUserReducer(state = constructInitialState()) {
  return state;
}

export default currentUserReducer;
