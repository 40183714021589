import { get } from 'lodash';

import { User, Product, FontFamily, Order, StoreItem } from 'types';

interface GlobalData {
  currentUser?: User;
  currentOrder?: Order;
  showLicensing?: boolean;
  product?: Product;
  fontFamily?: FontFamily;
  storeItems?: StoreItem[];
  selectedTaxonId?: number | null;
  searchTerm?: string;
  countriesJSON?: string;

  licenseOptions?: {
    desktop: {
      id: number;
      label: string;
      multiplier: number;
    }[];

    web: {
      id: number;
      label: string;
      multiplier: number;
    }[];

    app: {
      id: number;
      label: string;
      multiplier: number;
    }[];
  };

  translations?: {
    licenseDescription: {
      desktop: { heading: string; content: string };
      web: { heading: string; content: string };
      app: { heading: string; content: string };
    };

    licenseAgreement: {
      desktop: {
        heading: string;
        content: string;
      };

      web: {
        heading: string;
        content: string;
      };

      app: {
        heading: string;
        content: string;
      };
    };
  };
}

export default function globalData(): GlobalData {
  return get(window, 'gon', {});
}
