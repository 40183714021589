export function setUnion(set: Set<any>, otherSet: Set<any>): Set<any> {
  return new Set([...set, ...otherSet]);
}

export function setSubtract(set: Set<any>, otherSet: Set<any>): Set<any> {
  return new Set([...set].filter((x) => !otherSet.has(x)));
}

export function setIsSuperset(set: Set<any>, otherSet: Set<any>): boolean {
  for (const elem of otherSet) {
    if (!set.has(elem)) {
      return false;
    }
  }
  return true;
}
