import { StoreItem } from 'types';

export interface UpdateSearchTermAction {
  type: 'UPDATE_SEARCH_TERM';
  searchTerm: string;
}

export function updateSearchTerm(searchTerm: string): UpdateSearchTermAction {
  return { type: 'UPDATE_SEARCH_TERM', searchTerm };
}

interface UpdateSearchResultsAction {
  type: 'UPDATE_SEARCH_RESULTS';
  searchResults: { id: number }[];
}

export function updateSearchResults(
  searchResults: { id: number }[]
): UpdateSearchResultsAction {
  return { type: 'UPDATE_SEARCH_RESULTS', searchResults };
}

interface SelectNextResultAction {
  type: 'SELECT_NEXT_RESULT';
}

export function selectNextResult(): SelectNextResultAction {
  return { type: 'SELECT_NEXT_RESULT' };
}

interface SelectPreviousResultAction {
  type: 'SELECT_PREV_RESULT';
}

export function selectPreviousResult(): SelectPreviousResultAction {
  return { type: 'SELECT_PREV_RESULT' };
}

interface UpdateSelectedTaxonAction {
  type: 'UPDATE_SELECTED_TAXON';
  selectedTaxonId: number;
}

export function updateSelectedTaxon(
  selectedTaxonId: number
): UpdateSelectedTaxonAction {
  return { type: 'UPDATE_SELECTED_TAXON', selectedTaxonId };
}

interface ResetOffsetAction {
  type: 'RESET_OFFSET';
}

export function resetOffset(): ResetOffsetAction {
  return { type: 'RESET_OFFSET' };
}

interface PushItemsAction {
  type: 'PUSH_ITEMS';
  items: StoreItem[];
}

export function pushItems(items: StoreItem[]): PushItemsAction {
  return { type: 'PUSH_ITEMS', items };
}

interface UpdateImageSizeAction {
  type: 'UPDATE_IMAGE_SIZE';
  imageSize: 'small' | 'medium' | 'large';
}

export type Action =
  | UpdateSearchTermAction
  | UpdateSearchResultsAction
  | SelectNextResultAction
  | SelectPreviousResultAction
  | UpdateSelectedTaxonAction
  | ResetOffsetAction
  | PushItemsAction
  | UpdateImageSizeAction;
