import { includes, findIndex } from 'lodash';
import globalData from 'utils/global-data';
import { CarouselAction } from './action-creators';

interface State {
  carouselItemIndex: number;
  imageModalIsVisible: boolean;
}

const initialState: State = {
  carouselItemIndex: 0,
  imageModalIsVisible: false
};

// Mod function supporting negative numbers (as much as is necessary). This is imperfect, but
// supports stepping back to -1.
function mod(number: number, mod: number): number {
  return (number + mod) % mod;
}

function productImagesReducer(
  state: State = initialState,
  action: CarouselAction
): State {
  switch (action.type) {
    case 'SELECT_CAROUSEL_ITEM': {
      return {
        ...state,
        carouselItemIndex: mod(action.index, carouselItemCount())
      };
    }

    case 'NEXT_CAROUSEL_ITEM': {
      return {
        ...state,
        carouselItemIndex: nextItem(state.carouselItemIndex, action.imagesOnly)
      };
    }

    case 'PREVIOUS_CAROUSEL_ITEM': {
      return {
        ...state,
        carouselItemIndex: prevItem(state.carouselItemIndex, action.imagesOnly)
      };
    }

    case 'SHOW_IMAGE_MODAL': {
      return { ...state, imageModalIsVisible: true };
    }

    case 'HIDE_IMAGE_MODAL': {
      return { ...state, imageModalIsVisible: false };
    }

    case 'UPDATE_SELECTED_VARIANT': {
      const { variantId } = action;
      return updateSelectedVariant(state, variantId);
    }

    default: {
      return state;
    }
  }
}

// Step to the next index in the carousel, wrapping past the end.
function nextIndexAfter(index: number): number {
  return mod(index + 1, carouselItemCount());
}

// Step to the previous index in the carousel, wrapping past the beginning.
function prevIndexBefore(index: number): number {
  return mod(index - 1, carouselItemCount());
}

// Step to the next index in the carousel, wrapping past the end, and skipping any videos if
// flagged.
function nextItem(currentIndex: number, imagesOnly: boolean): number {
  let newIndex = nextIndexAfter(currentIndex);
  while (
    imagesOnly &&
    typeAtIndex(newIndex) !== 'image' &&
    newIndex !== currentIndex
  ) {
    newIndex = nextIndexAfter(newIndex);
  }
  return newIndex;
}

// Step to the previous index in the carousel, wrapping past the beginning, and skipping any videos
// if flagged.
function prevItem(currentIndex: number, imagesOnly: boolean): number {
  let newIndex = prevIndexBefore(currentIndex);
  while (
    imagesOnly &&
    typeAtIndex(newIndex) !== 'image' &&
    newIndex !== currentIndex
  ) {
    newIndex = prevIndexBefore(newIndex);
  }
  return newIndex;
}

function typeAtIndex(index: number): 'image' | 'video' | null {
  const { product } = globalData();

  if (product == null) {
    return null;
  }

  return product.media[index].type;
}

function updateSelectedVariant(state: State, variantId: number | null): State {
  const { product } = globalData();

  if (product == null) {
    return state;
  }

  const media = product.media;
  const carouselItemIndex = state.carouselItemIndex;
  const currentMedia = media[carouselItemIndex];

  if (!includes(currentMedia.variant_ids, variantId)) {
    const nextMediaIndex = findIndex(media, (i) => {
      return includes(i.variant_ids, variantId);
    });

    if (nextMediaIndex !== -1) {
      return { ...state, carouselItemIndex: nextMediaIndex };
    }
  }

  return state;
}

function carouselItemCount(): number {
  const { product } = globalData();

  if (product == null) {
    return 0;
  }

  return product.media.length;
}

export default productImagesReducer;
