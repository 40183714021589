import { Order } from 'types';

export interface AddLineItemStartAction {
  type: 'ADD_LINE_ITEM_START';
  variantId: number;
  quantity: number;
}

export function addLineItemStart(values: {
  variantId: number;
  quantity: number;
}): AddLineItemStartAction {
  return {
    type: 'ADD_LINE_ITEM_START',
    ...values
  };
}

interface AddLineItemSuccessAction {
  type: 'ADD_LINE_ITEM_SUCCESS';
  order: Order;
}

export function addLineItemSuccess(order: Order): AddLineItemSuccessAction {
  return { type: 'ADD_LINE_ITEM_SUCCESS', order };
}

interface AddLineItemFailureAction {
  type: 'ADD_LINE_ITEM_FAILURE';
}

export function addLineItemFailure(): AddLineItemFailureAction {
  return { type: 'ADD_LINE_ITEM_FAILURE' };
}

export interface RemoveLineItemStartAction {
  type: 'REMOVE_LINE_ITEM_START';
  lineItemId: number;
  orderNumber: string;
}

export function removeLineItemStart(
  lineItemId: number,
  orderNumber: string
): RemoveLineItemStartAction {
  return { type: 'REMOVE_LINE_ITEM_START', lineItemId, orderNumber };
}

interface RemoveLineItemSuccessAction {
  type: 'REMOVE_LINE_ITEM_SUCCESS';
  lineItemId: number;
}

export function removeLineItemSuccess(
  lineItemId: number
): RemoveLineItemSuccessAction {
  return { type: 'REMOVE_LINE_ITEM_SUCCESS', lineItemId };
}

interface RemoveLineItemFailureAction {
  type: 'REMOVE_LINE_ITEM_FAILURE';
  lineItemId: number;
}

export function removeLineItemFailure(
  lineItemId: number
): RemoveLineItemFailureAction {
  return { type: 'REMOVE_LINE_ITEM_FAILURE', lineItemId };
}

export interface UpdateLineItemStartAction {
  type: 'UPDATE_LINE_ITEM_START';
  lineItemId: number;
  quantity: number;
  orderNumber: string;
}

export function updateLineItemStart(
  lineItemId: number,
  quantity: number,
  orderNumber: string
): UpdateLineItemStartAction {
  return { type: 'UPDATE_LINE_ITEM_START', lineItemId, quantity, orderNumber };
}

interface UpdateLineItemSuccessAction {
  type: 'UPDATE_LINE_ITEM_SUCCESS';
  lineItemId: number;
  total: number;
  quantity: number;
}

export function updateLineItemSuccess(
  lineItemId: number,
  total: number,
  quantity: number
): UpdateLineItemSuccessAction {
  return { type: 'UPDATE_LINE_ITEM_SUCCESS', lineItemId, total, quantity };
}

interface UpdateLineItemFailureAction {
  type: 'UPDATE_LINE_ITEM_FAILURE';
  lineItemId: number;
}

export function updateLineItemFailure(
  lineItemId: number
): UpdateLineItemFailureAction {
  return { type: 'UPDATE_LINE_ITEM_FAILURE', lineItemId };
}

export type Action =
  | AddLineItemStartAction
  | AddLineItemSuccessAction
  | AddLineItemFailureAction
  | RemoveLineItemStartAction
  | RemoveLineItemSuccessAction
  | RemoveLineItemFailureAction
  | UpdateLineItemStartAction
  | UpdateLineItemSuccessAction
  | UpdateLineItemFailureAction;
