import globalData from 'utils/global-data';
import { LicenseType, Order } from 'types';

interface Totals {
  desktop: number;
  web: number;
  app: number;
}

interface ModalOptions {
  heading: string;
  content: string;
  size: ModalOptionsSize;
}

type ModalOptionsSize = 'small' | 'large';

interface ToggleLicenseSectionAction {
  type: 'TOGGLE_LICENSE_SECTION';
  isVisible: boolean;
}

export function toggleLicenseSection(
  isVisible: boolean
): ToggleLicenseSectionAction {
  return { type: 'TOGGLE_LICENSE_SECTION', isVisible };
}

interface UpdateLicenseOptionAction {
  type: 'UPDATE_LICENSE_OPTION';
  licenseType: LicenseType;
  id: number | 'EXTRA';
}

export function updateLicenseOption(
  licenseType: LicenseType,
  id: number | 'EXTRA'
): UpdateLicenseOptionAction {
  return { type: 'UPDATE_LICENSE_OPTION', licenseType, id };
}

interface AddLicenseStylesAction {
  type: 'ADD_LICENSE_STYLES';
  licenseType: LicenseType;
  ids: number[];
}

export function addLicenseStyles(
  licenseType: LicenseType,
  ids: number[]
): AddLicenseStylesAction {
  return { type: 'ADD_LICENSE_STYLES', licenseType, ids };
}

interface AddDomainNamesAction {
  type: 'ADD_DOMAIN_NAMES';
  domainNames: string;
}

export function addDomainNames(domainNames: string): AddDomainNamesAction {
  return { type: 'ADD_DOMAIN_NAMES', domainNames };
}

interface RemoveLicenseStylesAction {
  type: 'REMOVE_LICENSE_STYLES';
  licenseType: LicenseType;
  ids: number[];
}

export function removeLicenseStyles(
  licenseType: LicenseType,
  ids: number[]
): RemoveLicenseStylesAction {
  return { type: 'REMOVE_LICENSE_STYLES', licenseType, ids };
}

interface RemoveDomainNamesAction {
  type: 'REMOVE_DOMAIN_NAMES';
  domainNames: string[];
}

export function removeDomainNames(
  domainNames: string[]
): RemoveDomainNamesAction {
  return { type: 'REMOVE_DOMAIN_NAMES', domainNames };
}

interface CalculateLicenseTotalsStartAction {
  type: 'CALCULATE_LICENSE_TOTALS_START';
}

export function calculateLicenseTotalsStart(): CalculateLicenseTotalsStartAction {
  return { type: 'CALCULATE_LICENSE_TOTALS_START' };
}

interface CalculateLicenseTotalsSuccessAction {
  type: 'CALCULATE_LICENSE_TOTALS_SUCCESS';
  totals: Totals;
}

export function calculateLicenseTotalsSuccess(
  totals: Totals
): CalculateLicenseTotalsSuccessAction {
  return { type: 'CALCULATE_LICENSE_TOTALS_SUCCESS', totals };
}

interface CalculateLicenseTotalsFailedAction {
  type: 'CALCULATE_LICENSE_TOTALS_FAILED';
}

export function calculateLicenseTotalsFailed(): CalculateLicenseTotalsFailedAction {
  return { type: 'CALCULATE_LICENSE_TOTALS_FAILED' };
}

interface AddLicenseToOrderStartAction {
  type: 'ADD_LICENSE_TO_ORDER_START';
}

export function addLicenseToOrderStart(): AddLicenseToOrderStartAction {
  return { type: 'ADD_LICENSE_TO_ORDER_START' };
}

interface AddLicenseToOrderSuccessAction {
  type: 'ADD_LICENSE_TO_ORDER_SUCCESS';
  order: Order;
}

export function addLicenseToOrderSuccess(
  order: Order
): AddLicenseToOrderSuccessAction {
  return { type: 'ADD_LICENSE_TO_ORDER_SUCCESS', order };
}

interface AddLicenseToOrderFailedAction {
  type: 'ADD_LICENSE_TO_ORDER_FAILED';
}

export function addLicenseToOrderFailed(): AddLicenseToOrderFailedAction {
  return { type: 'ADD_LICENSE_TO_ORDER_FAILED' };
}

interface MissingDomainNameAction {
  type: 'MISSING_DOMAIN_NAME';
}

export function missingDomainName(): MissingDomainNameAction {
  return { type: 'MISSING_DOMAIN_NAME' };
}

interface ShowLicensingAgreementModalAction {
  type: 'SHOW_LICENSING_MODAL';
  modalOptions: ModalOptions;
}

export function showLicensingAgreementModal(
  licenseType: LicenseType
): ShowLicensingAgreementModalAction {
  const { translations } = globalData();

  if (translations == null) {
    throw new Error('Missing translations.');
  }

  const modalOptions = {
    heading: translations.licenseAgreement[licenseType].heading,
    content: translations.licenseAgreement[licenseType].content,
    size: 'large' as ModalOptionsSize
  };

  return { type: 'SHOW_LICENSING_MODAL', modalOptions };
}

interface ShowLicensingDescriptionModalAction {
  type: 'SHOW_LICENSING_MODAL';
  modalOptions: ModalOptions;
}

export function showLicensingDescriptionModal(
  licenseType: LicenseType
): ShowLicensingDescriptionModalAction {
  const { translations } = globalData();

  if (translations == null) {
    throw new Error('Missing translations.');
  }

  const modalOptions = {
    heading: translations.licenseDescription[licenseType].heading,
    content: translations.licenseDescription[licenseType].content,
    size: 'small' as ModalOptionsSize
  };

  return { type: 'SHOW_LICENSING_MODAL', modalOptions };
}

interface HideLicensingModalAction {
  type: 'HIDE_LICENSING_MODAL';
}

export function hideLicensingModal(): HideLicensingModalAction {
  return { type: 'HIDE_LICENSING_MODAL' };
}

export type Action =
  | ToggleLicenseSectionAction
  | UpdateLicenseOptionAction
  | AddLicenseStylesAction
  | AddDomainNamesAction
  | RemoveLicenseStylesAction
  | RemoveDomainNamesAction
  | CalculateLicenseTotalsStartAction
  | CalculateLicenseTotalsSuccessAction
  | CalculateLicenseTotalsFailedAction
  | AddLicenseToOrderStartAction
  | AddLicenseToOrderSuccessAction
  | AddLicenseToOrderFailedAction
  | MissingDomainNameAction
  | ShowLicensingAgreementModalAction
  | ShowLicensingDescriptionModalAction
  | HideLicensingModalAction;
