import { call, put, takeEvery } from 'redux-saga/effects';
import * as actionCreators from './action-creators';
import * as requests from './requests';

function* productsSaga() {
  yield takeEvery('UPDATE_SEARCH_TERM', updateSearchTermSaga);
}

export function* updateSearchTermSaga(
  action: actionCreators.UpdateSearchTermAction
) {
  const { searchTerm } = action;

  try {
    // @ts-ignore;
    const response = yield call(requests.performSearch, searchTerm);

    yield put(actionCreators.updateSearchResults(response));
  } catch (error) {
    yield put(actionCreators.updateSearchResults([]));
  }
}

export default productsSaga;
