import { find, get } from 'lodash';
import globalData from 'utils/global-data';

const initialState = {
  ...(globalData().product || {}),
  selectedVariantId: get(firstInStockVariant(), 'id'),
  lineItemCreated: false,
  primaryTaxonId: get(globalData(), 'product.primary_taxon_id')
};

type Action =
  | {
      type: 'UPDATE_SELECTED_VARIANT';
      variantId: number;
    }
  | {
      type: 'ADD_LINE_ITEM_START';
      lineItemCreated: boolean;
    }
  | {
      type: 'ADD_LINE_ITEM_SUCCESS';
      lineItemCreated: boolean;
    };

function productReducer(state = initialState, action: Action) {
  switch (action.type) {
    case 'UPDATE_SELECTED_VARIANT':
      return { ...state, selectedVariantId: action.variantId };

    case 'ADD_LINE_ITEM_START':
      return { ...state, lineItemCreated: false };

    case 'ADD_LINE_ITEM_SUCCESS':
      return { ...state, lineItemCreated: true };

    default:
      return state;
  }
}

function firstInStockVariant() {
  const product = globalData().product;
  if (product === undefined) {
    return null;
  }

  return find(product.variants, 'in_stock');
}

export default productReducer;
