import { isEmpty, get } from 'lodash';
import globalData from 'utils/global-data';
import Cookies from 'js-cookie';

import GlobalState from 'GlobalState';
import { Action } from './action-creators';

const MAILING_LIST_PATHNAME_PATTERNS = [/^\/$/, /^\/(fonts|store|work).*$/];

const initialState: GlobalState['options'] = {
  searchIsVisible: !isEmpty(globalData().searchTerm),
  navigationIsVisible: false,
  modalName: shouldShowMailingList() ? 'MAILING_LIST' : null,
  showNewsletterSignupOnHomePage: shouldShowNewsletterSignupOnHomePage()
};

function optionsReducer(state = initialState, action: Action) {
  switch (action.type) {
    case 'TOGGLE_SEARCH_VISIBILITY': {
      const searchIsVisible =
        action.isVisible != null ? action.isVisible : !state.searchIsVisible;

      state = { ...state, searchIsVisible };

      if (searchIsVisible) {
        state = { ...state, navigationIsVisible: false };
      }

      return state;
    }

    case 'TOGGLE_NAVIGATION_VISIBILITY': {
      const navigationIsVisible =
        action.isVisible != null
          ? action.isVisible
          : !state.navigationIsVisible;

      state = { ...state, navigationIsVisible };

      if (navigationIsVisible) {
        state = { ...state, searchIsVisible: false, modalName: null };
      }

      return state;
    }

    case 'SHOW_MODAL':
      return {
        ...state,
        modalName: action.modalName,
        navigationIsVisible: false
      };

    case 'HIDE_MODAL':
      return { ...state, modalName: null };

    default:
      return state;
  }
}

// Returns true if the mailing list sign up modal should be presented to the user
function shouldShowMailingList() {
  const pathname = get(window, 'location.pathname');

  // Confirm that current pathname is whitelisted
  if (
    !MAILING_LIST_PATHNAME_PATTERNS.some((pattern) => pattern.test(pathname))
  ) {
    return false;
  }

  // Confirm that current user isn't already subscribed to the mailing list
  if (get(globalData(), 'currentUser.receive_email') === true) {
    return false;
  }

  return Cookies.get('hide_mailing_list_form') == null;
}

function shouldShowNewsletterSignupOnHomePage() {
  if (get(globalData(), 'currentUser.receive_email') === true) {
    return false;
  }

  return Cookies.get('hide_mailing_list_form') == null;
}

export default optionsReducer;
