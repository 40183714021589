import { removeIn, updateIn } from 'immutable';

import globalData from 'utils/global-data';
import GlobalState from 'GlobalState';
import { Action } from './action-creators';

const initialState = globalData().currentOrder || null;

function currentOrderReducer(
  state: GlobalState['currentOrder'] = initialState,
  action: Action
) {
  if (state == null) {
    return state;
  }

  switch (action.type) {
    case 'ADD_LINE_ITEM_START':
      return state;

    case 'REMOVE_LINE_ITEM_START':
      return state;

    case 'UPDATE_LINE_ITEM_START':
      return state;

    case 'ADD_LINE_ITEM_SUCCESS':
      return action.order;

    case 'REMOVE_LINE_ITEM_SUCCESS': {
      const lineItemIndex = state.line_items.findIndex((lineItem) => {
        return lineItem.id === action.lineItemId;
      });

      return removeIn(state, ['line_items', lineItemIndex]);
    }

    case 'UPDATE_LINE_ITEM_SUCCESS': {
      const lineItemIndex = state.line_items.findIndex((lineItem) => {
        return lineItem.id === action.lineItemId;
      });

      return updateIn(state, ['line_items', lineItemIndex], (lineItem) => ({
        // @ts-ignore;
        ...lineItem,
        quantity: action.quantity,
        total: action.total,
        updatedAt: new Date().getTime()
      }));
    }

    default:
      return state;
  }
}

export default currentOrderReducer;
